import Registry from "./models/Registry";
import Accordion from "./components/Accordion"
import VimeoEmbed from "./components/VimeoEmbed";
import Modal from "./components/Modal";
import './components/Fadein';
import './components/MainMenu';
import SliderFlipcards from "./components/SliderFlipCards";
import SliderCards from './components/SliderCards';
import GMap from './components/GMap';
import NavigationSticky from './components/NavigationSticky';
import SliderTreElementi from './components/SliderTreElementi';
import Brevetto from "./components/Brevetto";
import Mappe from "./components/Mappe";
import SliderSerpentone from "./components/SliderSerpentone";

class App {
  constructor(context) {
    this.registry = new Registry();
    this.registerComponents();
    this.initController(context);
    this.patchIos();
    this.setvh();
  }

  initController(contextData) {
    const Context = this.registry.getContext(contextData);
    new Context(contextData);
  }

  registerComponents() {
    this.registry
      .addComponent('SliderFlipcards', SliderFlipcards)
      .addComponent('Accordion', Accordion)
      .addComponent('VimeoEmbed', VimeoEmbed)
      .addComponent('Modal', Modal)
      .addComponent('SliderCards', SliderCards)
      .addComponent('Accordion', Accordion)
      .addComponent('GMap', GMap)
      .addComponent('NavigationSticky', NavigationSticky)
      .addComponent('SliderTreElementi', SliderTreElementi)
      .addComponent('Brevetto', Brevetto)
      .addComponent('Mappe', Mappe)
      .addComponent('SliderSerpentone', SliderSerpentone)
    ;
  }

  patchIos() {
    window.addEventListener('resize', () => this.appHeight())
    this.appHeight()
  }

  appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  setvh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  
}

window.AppInit = function(context) {
    new App(context);
};
