import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery'

export default class SliderTreElementi extends SwiperSlider {
 constructor(element, context) {
  super(element, context);
  $(() => {
   
   this.options = {
    initialSlide: this.element.data('last') ? this.element[0].querySelectorAll('.swiper-slide').length : 1,

    navigation: {
     nextEl: this.element.find('.swiper-button-next')[0],
     prevEl: this.element.find('.swiper-button-prev')[0],
    },
    spaceBetween: 0,
    slidesPerView: 1.3,
    breakpoints: {
     1024: {
      slidesPerView: 3,
      spaceBetween: 0,
     },
     1224: {
      slidesPerView: 5,
      spaceBetween: 0,
     },
    },
    centeredSlides: false,
    loop: false,
   };

   $(() => {
    this.init()
   })
  });
 }
}