import $ from 'jquery'
import Component from  "../models/Component"

export default class GMap extends Component {
  static _initializers = []

  constructor (element, context) {
    super(element, context)

    

    this.mapStyle = [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]

    // Add the initialization of this GMap instance to the list of all
    // gMaps to be initialized
    GMap._initializers.push(() => {
      this.initGoogleMaps()
    })

    $(() => {
      this.lat = parseFloat(this.getData('lat'));
      this.lng = parseFloat(this.getData('lng'));
      this.zoom = this.getData('zoom') == false  ? parseFloat(14) : this.getData('lzoom');
      this.url = this.getData('url');
      this.loadGoogleMaps()
    })
  }

  // This script will be executed after the googleapis js is loaded
  initGoogleMaps () {
    this.center = new google.maps.LatLng(this.lat, this.lng)
    this.map = new google.maps.Map(this.element.get(0), {
      zoom: this.zoom,
      minZoom: 4,
      center: this.center,
      scrollwheel: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      rotateControl: false,
      styles: this.mapStyle,
    })

    this.pinMaps = window.location.origin+'/wp-content/uploads/2024/09/pin.png'

    this.marker = new google.maps.Marker({
      position: this.center,
      map: this.map,
      icon: this.pinMaps,
      
    })
    this.marker.addListener("click", () => {
      this.onMarkerClick();
    })
    this.element.parent().removeClass('loading')
  }

  onMarkerClick() {
    window.open(this.url, '_blank');
  }

  loadGoogleMaps () {
    // Append google apis script if not already present
    if (!window.initGoogleMaps) {
      window.initGoogleMaps = () => {
        GMap.initAllGoogleMaps()
      }
      var scriptSrc = 'https://maps.googleapis.com/maps/api/js?key=' + window._gTheme_.google_maps_api_key + '&callback=initGoogleMaps'
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = scriptSrc
      script.async = true;
      script.defer = true;
      document.body.appendChild(script)
    }
  }

  static initAllGoogleMaps () {
    GMap._initializers.forEach(function (initializer) {
      initializer()
    })
  }
}
