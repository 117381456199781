import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery';



export default class SliderCards extends SwiperSlider {
 constructor(element, context) {
  super(element, context);
  $(() => {
   this.options = {
    effect: 'carousel',
    carouselEffect: {
      // opacity change per side slide
      opacityStep: 0.33,
      // scale change per side slide
      scaleStep: 0.2,
      // amount of side slides visible, can be 1, 2 or 3
      sideSlides: 1,
    },
    grabCursor: true,
    loop: true,
    //loopAdditionalSlides: 1,
    slidesPerView: 'auto',
    navigation: {
     nextEl: ".swiper-button-next-cards",
     prevEl: ".swiper-button-prev-cards",
    },
   };

   $(() => {
    this.init()
   })
  });
 }
}