import $ from 'jquery'
import Component from "../models/Component"

export default class Mappe extends Component {
    constructor(element, context) {
        super(element, context);
        $(() => {


            // Funzione per gestire la visualizzazione dei gruppi e delle immagini
            function switchMap(gruppoToShow, immagineToShow) {
                $('.gruppo-primario, .gruppo-secondario, .gruppo-terziario').hide();
                $('.immagine-mappa-1, .immagine-mappa-2, .immagine-mappa-3').hide();
                $('.box-hover').hide();
                $(gruppoToShow).show();
                $(immagineToShow).show();
            }

            // Funzione per gestire la visualizzazione dei bottoni next/back
            function toggleButtons(showNextPrimario, showNextSecondario, showBackSecondario, showBackTerziario) {
                $('.next-map-primario').toggle(showNextPrimario);
                $('.next-map-secondario').toggle(showNextSecondario);
                $('.back-map-secondario').toggle(showBackSecondario);
                $('.back-map-terziario').toggle(showBackTerziario);
            }

            // Evento per mostrare il gruppo primario
            $('.show-primario').on('click', function () {
                toggleButtons(true, false, false, false); // Mostra next-primario, nascondi gli altri
                switchMap('.gruppo-primario', '.immagine-mappa-1');
            });

            // Evento per mostrare il gruppo secondario
            $('.show-secondario').on('click', function () {
                toggleButtons(false, true, true, false); // Mostra next-secondario e back-secondario
                switchMap('.gruppo-secondario', '.immagine-mappa-2');
            });

            // Evento per tornare al gruppo secondario dal terziario
            $('.back-map-terziario').on('click', function () {
                toggleButtons(false, true, true, false); // Mostra next-secondario e back-secondario
                switchMap('.gruppo-secondario', '.immagine-mappa-2');
            });

            // Evento per mostrare il gruppo terziario
            $('.show-terziario').on('click', function () {
                toggleButtons(false, false, false, true); // Mostra solo back-terziario
                switchMap('.gruppo-terziario', '.immagine-mappa-3');
            });


            $('.show-info').on('click', function () {
                $('.immagine-logo').fadeOut();
                var id = $(this).attr('id');

                $('.box-hover').each(function () {
                    if ($(this).attr('data-id') === id) {
                        $(this).fadeIn();
                    } else {
                        $(this).hide();
                    }
                });
            });


        });
    }
}