import $ from 'jquery';
import Component from '../models/Component';

export default class NavigationSticky extends Component {
    constructor(element, context) {
        super(element, context);

        $(() => {

            // document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            //     anchor.addEventListener('click', function(e) {
            //         e.preventDefault();
            
            //         const targetElement = document.querySelector(this.getAttribute('href'));
            //         const offset = 300;  // Altezza dei tuoi componenti sticky
            
            //         // Calcola la posizione dell'elemento, tenendo conto dell'offset
            //         const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;
            
            //         // Effettua lo scroll verso la nuova posizione
            //         window.scrollTo({
            //             top: elementPosition,
            //             behavior: 'smooth'
            //         });
            //     });
            // });

            
            function updateActiveLink() {

                $('.navigation_link').removeClass('active');
                const hash = window.location.hash.substring(1);
                if (hash) {
                    const activeLink = $('a.' + hash);
                    if (activeLink.length) {
                        activeLink.addClass('active');
                    }
                }
            }

            updateActiveLink();
            $(window).on('hashchange', updateActiveLink);

            $(window).on('scroll', function() {
                const scrollPosition = $(window).scrollTop();
                let currentSection = '';

                $('section').each(function() {
                    const sectionTop = $(this).offset().top;
                    const sectionHeight = $(this).outerHeight();

                    if (scrollPosition >= sectionTop - sectionHeight / 2) {
                        currentSection = $(this).attr('id');
                    }
                });

                if (currentSection) {
                    history.replaceState(null, null, '#' + currentSection);
                    updateActiveLink();
                }
            });
        });
    }
}
