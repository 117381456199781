import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery'

export default class SliderFlipcards extends SwiperSlider {
 constructor(element, context) {
  super(element, context);
  $(() => {
   this.options = {
    pagination: {
     el: this.element.find('.swiper-pagination')[0],
    },
    navigation: {
     nextEl: this.element.find('.swiper-button-next')[0],
     prevEl: this.element.find('.swiper-button-prev')[0],
    },
    spaceBetween: 10,
    slidesPerView: 1,
    breakpoints: {
     768: {
      slidesPerView: 1.5,
      spaceBetween: 30,
     },
     1420: {
      slidesPerView: 2,
      spaceBetween: 33,
     },
    },
    centeredSlides: false,
    loop: false,
   };

   $(() => {
    this.init()
   })
  });
 }
}