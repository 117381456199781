import $ from 'jquery';
import Component from '../models/Component';

export default class Accordion extends Component {
    constructor(element, context) {
        super(element, context);

        $( document ).ready(function() {
  
            const questions = document.querySelectorAll(".faq_question");

            questions.forEach((question) => {
                const icon = question.querySelector(".icon-shape");

                question.addEventListener("click", (_event) => {
                    const active = document.querySelector(".faq_question.active");
                    const activeIcon = document.querySelector(".icon-shape.active");

                    if (active && active !== question) {
                        active.classList.toggle("active");
                        if (activeIcon) {
                            activeIcon.classList.toggle("active");
                        }
                        const next = active.nextElementSibling;
                        if (next && next.style) {
                            next.style.maxHeight = '0px';
                        }
                    }

                    question.classList.toggle("active");
                    if (icon) {
                        icon.classList.toggle("active");
                    }

                    const answer = question.nextElementSibling;
                    if (answer && answer.style) {
                        if (question.classList.contains("active")) {
                            answer.style.maxHeight = answer.scrollHeight + "px";
                        } else {
                            answer.style.maxHeight = '0px';
                        }
                    }

                });
                
            });
            
        });

        
    }
}